.bannerSwiper {
    height: 700px;
}
.bannerSwiper img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    filter: grayscale(100%);
}

/* .bannerSwiperImage::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: url('../../../public/images/aa.jpg');
    opacity: 0.1; 
    pointer-events: none;
} */
.aboutMe {
    padding: 50px 20px;
    text-align: left;
}
.aboutMe {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.aboutMe a {
    background-color: #b9b1a7;
    padding: 15px 40px;
    width: fit-content;
    color: white;
    transition: 0.3s;
}
.aboutMe a:hover {
    background-color: black;
}
.comercialDis {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
}
